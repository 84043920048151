import {Injectable} from '@angular/core';
import jwtDecode from 'jwt-decode';
import {Jwt} from '../../shared/models/Jwt.model';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {
  }

  getJwtToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  saveJwtToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  destroyJwtToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  getDecodedJwtToken() {
    return jwtDecode<Jwt>(this.getJwtToken()!);
  }
}
