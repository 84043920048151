import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ERole} from '../../shared/models/ERole.model';
import {TokenService} from './token.service';
import {Restaurant} from '../../features/restaurants/models/Restaurant.model';
import {User} from "../../features/users/models/User.model";

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  constructor(private router: Router, private tokenService: TokenService) {
  }

  saveToken(token: string) {
    this.tokenService.saveJwtToken(token);
  }

  public getCurrentUser() {
    const token = this.tokenService.getDecodedJwtToken();
    return {
      id: token.id,
      firstName: token.firstName,
      lastName: token.lastName,
      email: token.sub,
      isAdmin: token.isAdmin,
    } as User;
  }

  public getCurrentRestaurant() {
    const token = this.tokenService.getDecodedJwtToken();
    return {
      id: token.restaurantId,
    } as Restaurant;
  }

  public getCurrentRole() {
    return this.tokenService.getDecodedJwtToken().role;
  }

  public userHasMultipleRoles() {
    return this.tokenService.getDecodedJwtToken().nbRoles > 0;
  }

  public isLoggedIn() {
    return this.tokenService.getJwtToken() !== null;
  }

  public signOut() {
    this.tokenService.destroyJwtToken();
    this.router.navigate(['auth']);
  }

  public isWorker() {
    return this.getCurrentRole() === ERole.WORKER;
  }

  isManager() {
    return this.getCurrentRole() === ERole.MANAGER;
  }

  isAdmin() {
    return this.getCurrentRole() === ERole.ADMIN;
  }
}
